"use client";

/**
 * Third-party libraries.
 */

/**
 * Project components.
 */
import { CommunicationLogCard, CommunicationLogCardProps, CommunicationLogRow } from "@/components/client/communication-log";
import { InboxEmptyWithShadowsIcon } from "@/components/client/svgs/icons";
import Icon from "@ant-design/icons";

// =============================================================================
// Communication Log Group
// =============================================================================

/**
 * A group of communication logs.
 *
 * This is grouped by a certain field like date.
 */
type CommunicationLogDataGroup = {
  /**
   * Logical group label for the communication logs.
   *
   * @example "Today"
   * @example "Yesterday"
   * @example "01 Jan 2024"
   */
  label: string;
  /**
   * Communication logs to display.
   */
  logs: CommunicationLogCardProps["data"][];
};

/**
 * Communication Log Group Props.
 */
export type CommunicationLogGroupProps = {
  /**
   * Communication log data group.
   */
  data: CommunicationLogDataGroup;
  /**
   * Callback function when a communication log is clicked.
   */
  onLogClick: CommunicationLogCardProps["onClick"];
};

/**
 * Groups the communication logs together.
 * We usually use the
 */
export const CommunicationLogGroup = ({
  data,
  onLogClick
}: CommunicationLogGroupProps) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className="" data-sentry-component="CommunicationLogGroup" data-sentry-source-file="communication-log-group.tsx">
      <CommunicationLogRow key={data.label} className="sticky top-0 z-10 bg-white" data-sentry-element="CommunicationLogRow" data-sentry-source-file="communication-log-group.tsx">
        <div className="flex items-center px-4 py-2">
          <div className="text-sm font-normal text-tpl-navy">{data.label}</div>
        </div>
      </CommunicationLogRow>
      {data.logs.map(log => <CommunicationLogRow key={log.id}>
          <CommunicationLogCard data={log} onClick={onLogClick} />
        </CommunicationLogRow>)}
      {!data.logs.length && <div className="flex min-h-[91px] w-full items-center justify-center p-4 text-sm text-tpl-navy-light">
          <div className="flex h-full w-full flex-col items-center justify-center gap-0">
            <div>
              <Icon component={InboxEmptyWithShadowsIcon} className="!text-4xl !text-tpl-navy-light" />
            </div>
            <div className="text-center text-xs text-tpl-navy-light">
              No logs found
            </div>
          </div>
        </div>}
    </div>;
};