export const APPLICATION_CONFIGURATION = {
  /**
   * Configuration for calls.
   */
  call: {
    /**
     * Configuration for call assignment.
     */
    assignment: {
      /**
       * Number of milliseconds to wait before a call can be reassigned to the
       * same agent.
       *
       * @default 10 seconds.
       */
      cooldown: 10000,
    },
    /**
     * Configuration for inbound calls.
     */
    inbound: {
      /**
       * Call assignment timeout in milliseconds.
       * The call will be reassigned if the call hasn't been picked up yet after
       * this timeout.
       *
       * @default 30 seconds.
       */
      timeout: 30000,
    },
    /**
     * Configuration for outbound calls.
     */
    outbound: {
      /**
       * Outbound call time out. The call will be considered missed if it's not
       * picked up after this timeout.
       *
       * @default 45 seconds.
       */
      timeout: 45000,
    },
  },
  /**
   * Configuration for pagination.
   */
  pagination: {
    /**
     * Default zero-based index to start fetching records.
     *
     * @example
     * 0
     */
    offset: 0,
    /**
     * Default number of records to fetch per page.
     *
     * @example
     * 10
     */
    limit: 10,
  },
  /**
   * Configuration for the queue.
   */
  queue: {
    /**
     * The interval in milliseconds to try to assign unuassigned calls.
     */
    interval: 5000,
  },
};
