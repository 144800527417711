export enum ErrorMessage {
  INVALID_ARGUMENT = "Invalid argument",
  MISSING_ARGUMENT = "Missing argument",
  NO_UPDATE_INPUT_PROVIDED = "No update input provided",
  UNSUPPORTED_CALL_DIRECTION = "Unsupported call direction",
  UNAUTHORIZED = "User is not authorized to perform this action",
}

export enum CallErrorMessage {
  CALL_ALREADY_CONCLUDED = "Call already concluded",
  CALL_NOT_ASSIGNED = "Call not assigned",
  CALL_NOT_FOUND = "Call not found",
  CALL_NOT_CREATED = "Call not created",
  CALL_STATUS_NOT_SUPPORTED = "Call status not supported",
  CANNOT_ACCEPT_CALL = "Cannot accept call",
  CANNOT_ASSIGN_CALL = "Cannot assign call",
  CANNOT_CREATE_CALL = "Cannot create call",
  CANNOT_COMPLETE_CALL = "Cannot complete call",
  CANNOT_GET_CALL = "Cannot get call",
  CANNOT_GET_CALLS = "Cannot get calls",
  CANNOT_HANG_UP_CALL = "Cannot hang up call",
  CANNOT_REJECT_CALL = "Cannot reject call",
  CANNOT_SYNC_CALL_LOG = "Cannot sync call log",
  CANNOT_UNASSIGN_CALL = "Cannot unassign call",
  CANNOT_UPDATE_CALL = "Cannot update call",
  CANNOT_UPDATE_CALL_SUMMARY = "Cannot update call summary",
  CANNOT_ADD_MISSED_CALL = "Cannot add missed call",
  CANNOT_ASSIGN_CALL_MISSED = "Cannot assign call missed",
  CANNOT_UNASSIGN_CALL_MISSED = "Cannot unassign call missed",
  FAILED_TO_UPDATE_TWILIO_CALL = "Failed to update Twilio call",
}

export enum CallRoutingErrorMessage {
  CANNOT_CREATE_CALL_ROUTING = "Cannot create call routing",
}

export enum ConferenceErrorMessage {
  CANNOT_ADD_CALL_TO_CONFERENCE = "Cannot add call to conference",
  CANNOT_END_CONFERENCE = "Cannot end conference",
}

export enum SystemPreferenceErrorMessage {
  CANNOT_CREATE_SYSTEM_PREFERENCE = "Cannot create system preference",
  CANNOT_GET_SYSTEM_PREFERENCE = "Cannot get system preference",
  CANNOT_UPDATE_SYSTEM_PREFERENCE = "Cannot update system preference",
}

export enum TwilioErrorMessage {
  CANNOT_CONNECT_TO_AGENT = "Cannot connect to agent",
  CANNOT_CREATE_CALL = "Cannot create call",
  CANNOT_HANGUP_CALL = "Cannot hangup call",
}

export enum UserErrorMessage {
  CANNOT_CREATE_USER = "Cannot create user",
  CANNOT_DELETE_USER = "Cannot delete user",
  CANNOT_GET_AVAILABLE_USER = "Cannot get available user",
  CANNOT_UPDATE_USER = "Cannot update user",
  CANNOT_UPDATE_USER_AVAILABILITY = "Cannot update user availability",
  NO_CURRENT_USER = "No current user",
  USER_NOT_FOUND = "User not found",
}

export enum PhoneNumberErrorMessage {
  INVALID_PHONE_NUMBER = "Invalid number",
}
