export const PhoneIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props} data-sentry-element="svg" data-sentry-component="PhoneIcon" data-sentry-source-file="phone-icon.tsx">
      <g clip-path="url(#clip0_722_6662)" data-sentry-element="g" data-sentry-source-file="phone-icon.tsx">
        <path d="M20.01 15.38C18.78 15.38 17.59 15.18 16.48 14.82C16.13 14.7 15.74 14.79 15.47 15.06L13.9 17.03C11.07 15.68 8.42 13.13 7.01 10.2L8.96 8.54C9.23 8.26 9.31 7.87 9.2 7.52C8.83 6.41 8.64 5.22 8.64 3.99C8.64 3.45 8.19 3 7.65 3H4.19C3.65 3 3 3.24 3 3.99C3 13.28 10.73 21 20.01 21C20.72 21 21 20.37 21 19.82V16.37C21 15.83 20.55 15.38 20.01 15.38Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="phone-icon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="phone-icon.tsx">
        <clipPath id="clip0_722_6662" data-sentry-element="clipPath" data-sentry-source-file="phone-icon.tsx">
          <rect width="24" height="24" fill="white" data-sentry-element="rect" data-sentry-source-file="phone-icon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};