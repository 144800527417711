"use client";

/**
 * Third-party libraries.
 */
import { Tooltip } from "antd";
import { useCallback, useMemo } from "react";

/**
 * Project components.
 */
import { ButtonCall, ButtonCallType } from "@/components/client/button";
import { CommunicationDirection, CommunicationLogStatus, CommunicationLogUser, useCommunicationLogContext } from "@/components/client/communication-log";
import { CommunicationLog } from "@/components/client/communication-log/types";
import { PhoneIcon } from "@/components/client/svgs/icons";
import { useTwilioContext } from "@/components/client/twilio";
import { ASSET_ROUTE } from "@/components/common/route";
import { PlayWrightTestId } from "@/tests/constants";
import Icon, { AudioMutedOutlined, AudioOutlined } from "@ant-design/icons";
import { CommunicationLogUtility } from "./utilities";

/**
 * Communication log card properties.
 */
export type CommunicationLogCardProps = {
  /**
   * Data to display on the card.
   */
  data: CommunicationLog;
  /**
   * Callback when the card is clicked.
   */
  onClick?: (args: {
    communicationLog: CommunicationLog;
  }) => void | Promise<void>;
  /**
   * Callback when an incoming call is accepted.
   *
   * Only available for incoming ringing calls.
   */
  onAccept?: () => void;
  /**
   * Callback when the an incoming call is rejected or an outbound call is
   * canceled by the user.
   *
   * Available for:
   * - Incoming ringing and ongoing calls.
   * - Outgoing ringing and ongoing calls.
   */
  onHangUp?: () => void;
};

/**
 * A single entry in the communication log list.
 */
export const CommunicationLogCard = ({
  data,
  onClick
}: CommunicationLogCardProps) => {
  const {
    callSid,
    clientName,
    clientPhoneNumber,
    direction,
    id,
    status,
    time,
    to,
    missedCount
  } = data;

  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const {
    accept,
    hangUp,
    isAccepting,
    isHangingUp,
    communicationLogsActiveLoading,
    communicationLogsConcludedLoading,
    selectedCommunicationLog
  } = useCommunicationLogContext();
  const {
    getCall,
    isMuted,
    toggleMute
  } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * Indicates that the call accept service is loading.
   */
  const acceptingCall = data?.id ? isAccepting({
    callId: data.id
  }) : false;

  /**
   * Indicates that the hang up call service is loading.
   */
  const hangingUpCall = data?.id ? isHangingUp({
    callId: data.id
  }) : false;

  /**
   * Indicates that the call accept service is loading.
   */
  const isAcceptButtonVisible = status === CommunicationLogStatus.RINGING && direction === CommunicationDirection.INBOUND;

  /**
   * Indicates that the call has a hang up button.
   */
  const isHangUpButtonVisible = (status === CommunicationLogStatus.RINGING || status === CommunicationLogStatus.ONGOING) && (direction === CommunicationDirection.INBOUND || direction === CommunicationDirection.OUTBOUND);

  /**
   * The icon to display based on the status of the communication.
   */
  const icon = useMemo(() => CommunicationLogUtility.getIcon({
    status
  }), [status]);

  /**
   * The colors to display based on the status of the communication.
   * This includes the following:
   * - Background color of the card
   * - Icon color
   * - Text color
   */
  const color = useMemo(() => {
    /**
     * Indicates that this communication log is actively selected.
     */
    const isSelected = selectedCommunicationLog?.id === id;
    return CommunicationLogUtility.getCardColor({
      active: isSelected,
      status
    });
  }, [id, selectedCommunicationLog?.id, status]);

  /**
   * Active Twilio call associated with this communication log card.
   *
   * This could be null if there is no active call.
   *
   * Active call means the call is connected to the Twilio server.
   */
  const twilioCall = useMemo(() => status === CommunicationLogStatus.ONGOING ? getCall({
    callId: data.id
  }) : undefined, [data.id, getCall, status]);

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /**
   * Card click handler.
   */
  const handleOnClick = useCallback(async () => {
    onClick?.({
      communicationLog: data
    });
  }, [data, onClick]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className={`flex w-full animate-slide-left cursor-pointer items-center gap-2 px-4 py-3 ${color.background} ${color.text} ${color.border} group hover:bg-neutral-light-grey`} data-testid={PlayWrightTestId.CommunicationLog.CARD} onClick={handleOnClick} data-sentry-component="CommunicationLogCard" data-sentry-source-file="communication-log-card.tsx">
      <Icon component={PhoneIcon} className={`!text-2xl ${color.icon} ${color.iconHover}`} rotate={icon === "phone-down" ? 135 : 0} data-sentry-element="Icon" data-sentry-source-file="communication-log-card.tsx" />
      <div className="flex-1">
        <div className={`text-tpl-navy ${color.text} text-sm font-semibold ${color.textHover}`} data-testid={PlayWrightTestId.CommunicationLog.CARD_CONTACT}>
          {clientName || clientPhoneNumber}
        </div>
        <div className="flex items-center justify-between">
          <div className={`text-gray-400 ${color.text} text-sm ${color.textHover}`} data-testid={PlayWrightTestId.CommunicationLog.CARD_STATUS_AND_DIRECTION}>
            {status} ({missedCount || direction})
          </div>
        </div>
        <div className="mt-1 flex w-full items-center justify-between">
          <CommunicationLogUser communicationLog={{
          id: data.id,
          missedCount: data.missedCount,
          user: data.user
        }} assignedUser={data.user}
        /** Only enabled when the call is inbound and the status is `Missed`, `Declined`, or `Canceled`. */ enabled={direction === CommunicationDirection.INBOUND && (status === CommunicationLogStatus.MISSED || status === CommunicationLogStatus.DECLINED || status === CommunicationLogStatus.CANCELED)} data-sentry-element="CommunicationLogUser" data-sentry-source-file="communication-log-card.tsx" />
          <div className={`text-nowrap text-xs text-tpl-navy-light ${status === CommunicationLogStatus.RINGING || status === CommunicationLogStatus.ONGOING ? "hidden" : ""} ${color.text} `}>
            {time}
          </div>
        </div>
        {/* Ring for inbound and outbound calls because we are not providing a ring tone to the user. */}
        {status === CommunicationLogStatus.RINGING && <audio autoPlay data-testid={PlayWrightTestId.CommunicationLog.CARD_AUDIO} loop src={ASSET_ROUTE.AUDIO.CALL_RINGTONE} />}
      </div>
      <div className="flex h-full items-center justify-center gap-2">
        {status === CommunicationLogStatus.ONGOING && <Tooltip title={isMuted({
        callId: data.id
      }) ? "Unmute" : "Mute"} style={{
        display: status === CommunicationLogStatus.ONGOING ? "block" : "none"
      }}>
            <ButtonCall data-testid={PlayWrightTestId.CommunicationLog.CARD_CALL_MUTE_BUTTON} disabled={!twilioCall} icon={isMuted({
          callId: data.id
        }) ? <AudioMutedOutlined className="!text-tpl-navy" /> : <AudioOutlined className="!text-tpl-navy" />} onClick={event => {
          event.stopPropagation();
          toggleMute({
            callId: data.id
          });
        }} type={ButtonCallType.MUTE} />
          </Tooltip>}
        {isAcceptButtonVisible && <ButtonCall data-testid={PlayWrightTestId.CommunicationLog.CARD_CALL_ACCEPT_BUTTON} disabled={acceptingCall || hangingUpCall || communicationLogsActiveLoading || communicationLogsConcludedLoading}
      // icon={<CustomIcon src="phone" className="!text-white" />}
      icon={<Icon className="!text-white" component={PhoneIcon} />} loading={acceptingCall} onClick={async event => {
        await accept({
          callId: data.id
        });
      }} type={ButtonCallType.ACCEPT} />}
        {isHangUpButtonVisible && <ButtonCall data-testid={PlayWrightTestId.CommunicationLog.CARD_CALL_HANG_UP_BUTTON} disabled={acceptingCall || hangingUpCall || communicationLogsActiveLoading || communicationLogsConcludedLoading ||
      // Call is ongoing but Twilio call is missing or not yet connected.
      status === CommunicationLogStatus.ONGOING && !twilioCall}
      // icon={<CustomIcon src="phone-down" className="!text-white" />}
      icon={<Icon className="!text-white" component={PhoneIcon} rotate={135} />} loading={hangingUpCall} onClick={async event => {
        event.stopPropagation();
        await hangUp({
          callId: data.id,
          direction: data.call.direction,
          status: data.call.status
        });
      }} type={ButtonCallType.HANG_UP} />}
      </div>
    </div>;
};