"use client";

/**
 * Project components.
 */
import { InboxEmptyWithShadowsIcon } from "@/components/client/svgs/icons";
import Icon from "@ant-design/icons";

/**
 * A section that is displayed when no communication log is selected.
 * This displays an icon and a text that says "Select a log".
 */
function CommunicationLogSelectALogPlaceholder() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className="flex h-full w-full flex-col items-center justify-center gap-2" data-sentry-component="CommunicationLogSelectALogPlaceholder" data-sentry-source-file="communication-log-select-a-log-placeholder.tsx">
      <div>
        <Icon component={InboxEmptyWithShadowsIcon} className="!text-6xl !text-tpl-navy-light" data-sentry-element="Icon" data-sentry-source-file="communication-log-select-a-log-placeholder.tsx" />
      </div>
      <div className="text-center text-sm text-tpl-navy-light">
        Select a log
      </div>
    </div>;
}
export default CommunicationLogSelectALogPlaceholder;