"use client";

/**
 * Third-party libraries.
 */
import { NotificationInstance } from "antd/es/notification/interface";
import React, { Dispatch, PropsWithChildren, SetStateAction, useCallback, useState } from "react";

/**
 * Project components
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { CallsActiveQuery, CallsUserActiveQuery, useCallsActiveQuery, UserAvailabilityStatus, UsersQuery, useSystemPreferenceEventSubscription, useUserEventSubscription, useUsersQuery, useUserUpdateAvailabilityMutation } from "@/components/client/graphql";
import { useNotificationContext } from "@/components/client/notification";

/**
 * Arguments of the set user availability status function.
 */
type SetUserAvailabilityStatusArgs = {
  /**
   * Indicates that only the local state would be updated.
   * Database entry will not be affected.
   */
  local?: boolean;
  /**
   * The user availability status.
   */
  status: UserAvailabilityStatus;
};

/**
 * Application context.
 */
export type ApplicationContext = {
  // ===========================================================================
  // Application
  // ===========================================================================
  /**
   * Runs the application in development mode.
   * Certain features not available in production are enabled in development mode.
   *
   * @example
   * development
   */
  mode?: "development";
  /**
   * Sets the mode of the application.
   */
  setMode: Dispatch<SetStateAction<"development" | undefined>>;
  // ===========================================================================
  // Active Calls
  // ===========================================================================
  /**
   * Active calls in the system.
   * You can use this to check what are the active calls in the system and
   * who are they assigned to.
   */
  activeCalls: CallsUserActiveQuery["callsUserActive"]["items"];
  /**
   * Sets the active calls in the system.
   */
  setActiveCalls: Dispatch<SetStateAction<CallsUserActiveQuery["callsUserActive"]["items"]>>;
  // ===========================================================================
  // Dialer
  // ===========================================================================
  /**
   * Indicates if the dialer is shown or hidden.
   *
   * @default false
   */
  showDialer: boolean;
  /**
   * Shows or hides the dialer.
   */
  setShowDialer: Dispatch<SetStateAction<boolean>>;
  // ===========================================================================
  // Notifications
  // ===========================================================================
  /**
   * Ant Design notification instance.
   *
   * Use this to show notifications.
   */
  notification: NotificationInstance;
  // ===========================================================================
  // Settings
  // ===========================================================================
  /**
   * Indicates if the settings modal is shown or hidden.
   */
  showSettings: boolean;
  /**
   * Shows or hides the settings modal.
   */
  setShowSettings: Dispatch<SetStateAction<boolean>>;
  // ===========================================================================
  // User
  // ===========================================================================
  /**
   * List of users in the system.
   */
  users: UsersQuery["users"];

  /**
   * Indicates if the users are currently loading.
   */
  usersLoading: boolean;
  // ===========================================================================
  // User Availability Status
  // ===========================================================================
  /**
   * User availability status setter.
   */
  setUserAvailabilityStatus: (args: SetUserAvailabilityStatusArgs) => void;
  /**
   * The availability status of the user.
   *
   * @default offline.
   */
  updatingUserAvailabilityStatus: boolean;
  /**
   * Updating user availability status.
   */
  userAvailabilityStatus: UserAvailabilityStatus;
};

/**
 * Application context.
 */
const ApplicationContext = React.createContext<ApplicationContext>({
  // ===========================================================================
  // Application
  // ===========================================================================
  mode: process.env.NEXT_PUBLIC_MODE,
  setMode: () => {},
  // ===========================================================================
  // Calls
  // ===========================================================================
  activeCalls: [],
  setActiveCalls: () => {},
  // ===========================================================================
  // Dialer
  // ===========================================================================
  setShowDialer: () => {},
  showDialer: false,
  // ===========================================================================
  // Notifications
  // ===========================================================================
  notification: {
    destroy: () => {},
    error: () => {},
    info: () => {},
    open: () => {},
    success: () => {},
    warning: () => {}
  },
  // ===========================================================================
  // Settings
  // ===========================================================================
  setShowSettings: () => {},
  showSettings: false,
  // ===========================================================================
  // User
  // ===========================================================================
  users: [],
  usersLoading: true,
  // ===========================================================================
  // User Availability Status
  // ===========================================================================
  setUserAvailabilityStatus: () => {},
  updatingUserAvailabilityStatus: false,
  userAvailabilityStatus: UserAvailabilityStatus.Offline
});

/**
 * Use Application Context hook.
 */
export const useApplicationContext = () => {
  return React.useContext(ApplicationContext);
};

/**
 * Application context provider.
 */
export const ApplicationContextProvider = ({
  children
}: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const {
    user
  } = useAuthenticationContext();
  const {
    notification
  } = useNotificationContext();
  const {
    data: usersResponse,
    loading: usersLoading,
    updateQuery: updateUsersQuery
  } = useUsersQuery();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [activeCalls, setActiveCalls] = useState<ApplicationContext["activeCalls"]>([]);
  const [mode, setMode] = useState<ApplicationContext["mode"]>(process.env.NEXT_PUBLIC_MODE);

  /**
   * Shows or hides the dialer.
   */
  const [showDialer, setShowDialer] = useState<ApplicationContext["showDialer"]>(false);

  /**
   * Shows or hides the settings modal.
   */
  const [showSettings, setShowSettings] = useState<ApplicationContext["showSettings"]>(false);

  /**
   * The user availability status.
   */
  const [userAvailabilityStatus, _setUserAvailabilityStatus] = useState<ApplicationContext["userAvailabilityStatus"]>(UserAvailabilityStatus.Offline);

  // ===========================================================================
  // ===========================================================================
  // Queries
  // ===========================================================================
  // ===========================================================================

  /**
   * Active calls query - calls that are currently in progress.
   *
   * This is only for fetching the initial active calls when the user logs in,
   * so that the user will be able to see the current active calls from the user list and
   * disable the callback button if a customer is already in a call with a user/agent.
   */
  useCallsActiveQuery({
    /** Don't query when no user is logged in. */
    skip: !user,
    /** Set the active calls when the query is completed. */
    onCompleted: (data: CallsActiveQuery) => {
      setActiveCalls(data.callsActive);
    }
  });

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const [updateUserAvailabilityStatus, {
    loading: updatingUserAvailabilityStatus
  }] = useUserUpdateAvailabilityMutation();

  /**
   * This automatically updates the user query, when a new event is received.
   * Not sure how it works though.
   */
  useUserEventSubscription({
    onData({
      data
    }) {
      updateUsersQuery(previousData => {
        /**
         * The updated user record which triggered the event.
         */
        const updatedUser = data.data?.userEvent;
        if (!updatedUser) {
          return previousData;
        }
        if (!previousData?.users) {
          return {
            ...previousData,
            users: []
          };
        }
        const existingUserIndex = previousData.users.findIndex(user => user.id === updatedUser.id);
        const updatedUsers = [...(previousData.users || [])];

        // Add the user record on the top of the list since it doesn't exist yet.
        if (existingUserIndex === -1) {
          updatedUsers.unshift(updatedUser);
        }
        // Update the user record if it exists in the list.
        else {
          updatedUsers.splice(existingUserIndex, 1, updatedUser);
        }
        updatedUsers.sort((a, b) => {
          return a.profile.fullName.localeCompare(b.profile.fullName);
        });
        return {
          ...previousData,
          users: updatedUsers
        };
      });
    }
  });

  /**
   * This automatically updates the systemPreference query, when a new event is received.
   * Not sure how it works though.
   *
   * TODO: Please investigate and indicate here how it works.
   */
  useSystemPreferenceEventSubscription();

  // ===========================================================================
  // ===========================================================================
  // Function
  // ===========================================================================
  // ===========================================================================

  /**
   * Updates the user availability status on the server first before updating the
   * state. This is to ensure that the status is synced to the server first
   * before reflecting changes.
   */
  const setUserAvailabilityStatus = useCallback(({
    local,
    status
  }: SetUserAvailabilityStatusArgs) => {
    const errorMessage = `Error updating user availability status to ${status}. Please try again.`;

    /**
     * Do not allow the user to update the status if the user is not logged in.
     */
    if (!user) {
      console.warn("User is not logged in.");
      return;
    }
    if (local) {
      _setUserAvailabilityStatus(status);
      return;
    }

    /**
     * Do not allow the user to update the status if the status is currently
     * being updated. This is to prevent multiple updates at the same time.
     */
    if (updatingUserAvailabilityStatus) {
      console.warn("User availability status is currently being updated.");
      return;
    }
    updateUserAvailabilityStatus({
      variables: {
        input: {
          availabilityStatus: status
        }
      },
      onCompleted: async function (data, clientOptions) {
        if (status !== data.userUpdateAvailability.availability.status) {
          console.error(errorMessage);
          notification.error({
            message: "Error updating user availability status.",
            description: errorMessage,
            showProgress: true,
            pauseOnHover: true
          });
          return;
        }
        _setUserAvailabilityStatus(data.userUpdateAvailability.availability.status);
      },
      onError(error, clientOptions) {
        const errorMessage = `Error updating user availability status to ${status}. Please try again.`;
        console.error(errorMessage, error);
        notification.error({
          message: "Error updating user availability status.",
          description: errorMessage,
          showProgress: true,
          pauseOnHover: true
        });
      }
    });
  }, [notification, updateUserAvailabilityStatus, updatingUserAvailabilityStatus, user]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <ApplicationContext.Provider value={{
    // ===========================================================================
    // Application
    // ===========================================================================
    mode,
    setMode,
    // ===========================================================================
    // Active Calls
    // ===========================================================================
    activeCalls,
    setActiveCalls,
    // ===========================================================================
    // Dialer
    // ===========================================================================
    setShowDialer,
    showDialer,
    // ===========================================================================
    // Notifications
    // ===========================================================================
    notification,
    // ===========================================================================
    // Settings
    // ===========================================================================
    setShowSettings,
    showSettings,
    // ===========================================================================
    // User
    // ===========================================================================
    users: usersResponse?.users || [],
    usersLoading,
    // ===========================================================================
    // User Availability Status
    // ===========================================================================
    setUserAvailabilityStatus,
    updatingUserAvailabilityStatus,
    userAvailabilityStatus
  }} data-sentry-element="unknown" data-sentry-component="ApplicationContextProvider" data-sentry-source-file="application-context.tsx">
      {children}
    </ApplicationContext.Provider>;
};