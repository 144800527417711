"use client";

import { debounce } from "@/components/common/utilities/debounce";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

/** Properties for the useDebounceValue hook. */
export type UseDebounceValueProps<T> = {
  /** The value to debounce. */
  value: T;
  /** The debounce time in milliseconds. Defaults to `500`. */
  ms: number;
};

/**
 * Hook for debouncing a value.
 * Used when you want to set a value after a delay.
 *
 * @param value The value to debounce.
 * @param ms The debounce time in milliseconds. Defaults to `500`.
 * @example
 * const [value, setValue] = useState(0);
 * const [debouncedValue, setDebouncedValue] = useDebouncedValue({ value, ms: 500 });
 *
 * // Somewhere in your code...
 * // This will set the debounced value to 1 after 500ms
 * setDebouncedValue(1);
 */
export function useDebouncedValue<T>(props: UseDebounceValueProps<T>): [T, Dispatch<SetStateAction<T>>] {
  const {
    value,
    ms = 500
  } = props;
  /** State for managing the debounced value. */
  const [debouncedValue, setDebouncedValue] = useState(value);

  /** Create the debounce function to be used for setting the delayed value by `ms`. */
  const debounceFn = useMemo(() => debounce(setDebouncedValue, ms), [setDebouncedValue, ms]);

  /** Effect that calls the debounce function when the value changes. */
  useEffect(() => {
    /** This will set the debounced  */
    debounceFn(value);
  }, [value, debounceFn]);
  return [debouncedValue, setDebouncedValue] as const;
}