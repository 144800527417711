import { CallStatus } from "@/components/client/graphql";
import { CALL_STATUSES_ONGOING_WITH_PENDING as PRISMA_CALL_STATUSES_ONGOING_WITH_PENDING } from "@/components/server/call/services/enumerations";
import { CallStatus as PrismaCallStatus } from "@prisma/client";

export const PRISMA_TO_GQL_CALL_STATUS: Record<PrismaCallStatus, CallStatus> = {
  PENDING: CallStatus.Pending,
  QUEUED: CallStatus.Queued,
  IN_PROGRESS: CallStatus.InProgress,
  CANCELED: CallStatus.Canceled,
  COMPLETED: CallStatus.Completed,
  FAILED: CallStatus.Failed,
  MISSED: CallStatus.Missed,
  REJECTED: CallStatus.Rejected,
  WRAPPING_UP: CallStatus.WrappingUp,
};

/**
 * Statuses that are considered as ongoing or pending calls. (user is currently dialing or in an ongoing call with a customer)
 */
export const CALL_STATUSES_ONGOING_WITH_PENDING: CallStatus[] =
  PRISMA_CALL_STATUSES_ONGOING_WITH_PENDING.map(
    (status) => PRISMA_TO_GQL_CALL_STATUS[status],
  );
